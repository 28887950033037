import {
  SetAccountTypeRequest,
  SetWorkspaceRequest,
  UpdateBasicInfoRequest,
} from '@msl/user-gateway-sdk';

export type RadioButtonOption = {
  value: string | number | boolean;
  label: string;
  className?: string;
};

export type OnboardingData = {
  userBasicInfo: UpdateBasicInfoRequest;
  accountTypeInfo: SetAccountTypeRequest;
  workspaceInfo: SetWorkspaceRequest;
  demoSession: 0 | 1 | undefined;
  demoScheduled: boolean;
  userEmail: string;
  userCountry: string;
  calendlyUrl: string;
};

export enum ResponseStatusCode {
  NO_ERRORS = 0,
  GATEWAY_ERROR = 1,
}

export type CompletedStepResponse = {
  statusCode?: ResponseStatusCode;
  eventName?: string;
  eventAttributes?: Record<string, string>;
  cioEventName?: string;
  cioEventAttributes?: Record<string, string>;
};
