<script setup lang="ts">
import { onBeforeMount, ref, computed, watch } from 'vue';
import { deferLoading, loadingDone, navigateTo } from '@mfl/framework';
import { auth4Sdks, WEBAPP_BASE_URL } from '@mfl/platform-shell';
import {
  toast,
  ToastStatus,
  WsButton,
  WsTooltip,
} from '@mfl/common-components';
import { onboardingGateway, OnboardingStatus } from '@msl/user-gateway-sdk';
import strings from './onboarding.strings';
import logoSrc from './assets/imgs/logo.svg';
import { OnboardingData, ResponseStatusCode } from './types';
import onboardingStrings from './onboarding.strings';
import { screenName, screens } from './consts';
import { setCalendlyUrl, sendAnalytics } from './form-actions';

const onboardingData = ref<OnboardingData>({
  userBasicInfo: {},
  accountTypeInfo: {},
  workspaceInfo: {},
  demoSession: undefined,
  demoScheduled: false,
  userEmail: '',
  userCountry: '',
  calendlyUrl: '',
});
const inProgress = ref<boolean>(false);
const currentStep = ref(-1);
const showOverlayMsg = ref<string>('');

onBeforeMount(async () => {
  try {
    deferLoading();
    await onboardingGateway.init(auth4Sdks());
    await initOnboardingData();
  } catch (e) {
    console.error('get onboarding status failed', e);
    errorNotification();
  } finally {
    loadingDone();
  }
});

const currentScreen = computed(() => {
  return screens[currentStep.value];
});

const isLastScreen = computed(() => {
  return currentStep.value == screens.length - 1;
});

const isFirstScreen = computed(() => {
  return currentStep.value == 0;
});

const isContinueBtnEnabled = ref<boolean>(false);

const scheduleDemoCompleted = computed(() => {
  return onboardingData.value.demoScheduled;
});

watch(scheduleDemoCompleted, async (val) => {
  if (val) {
    await formSubmit();
  }
});

function initCurrentStep(onboardingStatus: OnboardingStatus): void {
  if (!onboardingStatus?.sqlPage) {
    currentStep.value = 0;
  } else if (!onboardingStatus?.setupPage) {
    currentStep.value = 1;
  } else if (!onboardingStatus?.setWorkspace) {
    currentStep.value = 2;
  }
}

async function initOnboardingData(): Promise<void> {
  const resp = await onboardingGateway.getBasicInfo({});
  console.log('HERERE');
  console.log(resp);
  initCurrentStep(resp.onboardingStatus || {});
  onboardingData.value.userBasicInfo = {
    companyName: resp.companyName,
    companySize: resp.companySize,
    userMobile: resp.userMobile,
    userName: resp.userName,
    userTitle: resp.userTitle,
  };
  onboardingData.value.workspaceInfo.emailPlatform = resp.emailPlatform;
  onboardingData.value.accountTypeInfo.accountType = resp.accountType;
  onboardingData.value.accountTypeInfo.companyIndustry = resp.companyIndustry;
  onboardingData.value.userEmail = resp.userEmail || '';
  onboardingData.value.userCountry = resp.userCountry || '';
  setCalendlyUrl(onboardingData.value);
}

function enableContinue(val: boolean) {
  isContinueBtnEnabled.value = val;
}

function errorNotification() {
  toast({
    status: ToastStatus.Error,
    message: onboardingStrings.generalServerError,
    aid: 'ONBOARDING_ERROR_TOAST',
  });
}

function completeOnboarding() {
  setTimeout(() => {
    showOverlayMsg.value =
      onboardingStrings.completeOnboardingTakingToWorkspace;
    setTimeout(() => {
      sessionStorage.removeItem('domainId');
      sessionStorage.removeItem('domainKey');
      const route = scheduleDemoCompleted.value
        ? 'onboarding-demo'
        : 'onboarding';
      navigateTo(`${WEBAPP_BASE_URL}/${route}`, false, true);
    }, 1000);
  }, 1000);
}

function nextStep() {
  if (isLastScreen.value) {
    return;
  }
  currentStep.value++;
}

function prevStep() {
  if (isFirstScreen.value) {
    return;
  }
  currentStep.value--;
}

async function formSubmit() {
  inProgress.value = true;
  showOverlayMsg.value =
    currentScreen.value.aid == screenName.ONBOARD_SCREEN_NAME_YOUR_WORKSPACE
      ? onboardingStrings.completeOnboardingGettingReadyMsg
      : '';
  const resp = await currentScreen.value.action(onboardingData.value);
  await sendAnalytics(resp);
  if (
    showOverlayMsg.value &&
    resp.statusCode === ResponseStatusCode.NO_ERRORS
  ) {
    await completeOnboarding();
  } else {
    inProgress.value = false;
    showOverlayMsg.value = '';
    if (resp.statusCode === ResponseStatusCode.NO_ERRORS) {
      setCalendlyUrl(onboardingData.value);
      nextStep();
    } else {
      errorNotification();
    }
  }
}
</script>

<template>
  <div v-if="currentScreen" class="onboarding-container">
    <div class="form">
      <div class="form-content" :aid="currentScreen.aid">
        <img :src="logoSrc" />
        <component
          :is="currentScreen.form"
          v-model="onboardingData"
          @enable-to-continue="enableContinue"
        ></component>
      </div>
      <div :class="['footer', { 'justify-content-flex-end': isFirstScreen }]">
        <WsButton
          v-if="!isFirstScreen"
          color="gray-500"
          :label="strings.buttonBackLabel"
          size="lg"
          variant="text"
          icon="fa-regular fa-chevron-left"
          :aid="`SIGNUP_FLOW_BACK_BTN_${currentScreen.aid}`"
          @click="prevStep"
        />
        <WsButton
          color="primary"
          :label="strings.buttonContinueLabel"
          :disabled="!isContinueBtnEnabled"
          :loading="inProgress"
          size="lg"
          :aid="`SIGNUP_FLOW_CONTINUE_BTN_${currentScreen.aid}`"
          @click="formSubmit"
        >
          {{ strings.buttonContinueLabel }}
          <WsTooltip
            v-if="!isContinueBtnEnabled"
            aid="SIGNUP_FLOW_CONTINUE_BTN_TOOLTIP"
          >
            {{ strings.buttonContinueDisabledTooltip }}
          </WsTooltip>
        </WsButton>
      </div>
    </div>
    <div class="margin">
      <component :is="currentScreen.svg"></component>
    </div>
  </div>
  <div v-if="showOverlayMsg" class="overlay">
    <div class="message">
      <div class="text-sm font-semibold">{{ showOverlayMsg }}</div>
      <div class="loader"></div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.onboarding-container {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;

  .margin {
    display: flex;
    width: 33vw;
    padding: 298px 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(228deg, #ea3fb8 -0.01%, #1e90fa 116.52%);
  }

  .form {
    display: flex;
    width: 67vw;
    padding: 60px;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: #fff;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }

  .form-content {
    max-width: 600px;
  }

  .footer {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }
}

.overlay {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--Overlay, rgba(0, 0, 0, 0.7));

  .message {
    background-color: #fff;
    border-radius: 8px;
    padding: 6px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
  }

  .loader {
    display: block;
    visibility: hidden;
    width: 14px;
    aspect-ratio: 1;
    background: var(--Gray-500, #2d2e30);
    --b: 2px;
    --a: 135deg;
    border-radius: 50%;
    padding: var(--b);
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 97%, #0000);
    mask:
      top var(--_g),
      calc(50% + 50% * sin(var(--a))) calc(50% - 50% * cos(var(--a))) var(--_g),
      linear-gradient(#0000 0 0) content-box intersect,
      conic-gradient(#000 var(--a), #0000 0);
    background-repeat: no-repeat;
    animation: spin-me 1s infinite 500ms linear;
  }
}

@media screen and (max-width: 1000px) {
  .margin {
    display: none !important;
  }
  .form {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    background-color: yellowgreen;
  }
}
</style>
