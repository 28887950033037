<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { AccountType } from '@msl/user-gateway-sdk';
import onboardingStrings from '../../onboarding.strings';
import { OnboardingData, RadioButtonOption } from '../../types';
import radioButton from '../radio-button.vue';
import { sendAnalytics } from '../../form-actions';

const emit = defineEmits(['enableToContinue']);
const onboardingData = defineModel<OnboardingData>({
  required: true,
});
const accountTypeOptions = ref<RadioButtonOption[]>([
  { value: AccountType.SOLO, label: onboardingStrings.accountTypeOptionJustMe },
  {
    value: AccountType.TEAMS,
    label: onboardingStrings.accountTypeOptionCompany,
  },
]);
const companyIndustryOptions = ref<RadioButtonOption[]>([
  {
    value: 'TECHNOLOGY',
    label: onboardingStrings.companyIndustryOptionTechnology,
  },
  {
    value: 'REAL_ESTATE',
    label: onboardingStrings.companyIndustryOptionRealEstate,
  },
  {
    value: 'HEALTH_CARE',
    label: onboardingStrings.companyIndustryOptionHealthcare,
  },
  {
    value: 'BUSINESS_SERVICES',
    label: onboardingStrings.companyIndustryOptionBusiness,
  },
  { value: 'FINANCE', label: onboardingStrings.companyIndustryOptionFinance },
  {
    value: 'NON_PROFIT',
    label: onboardingStrings.companyIndustryOptionNonProfit,
  },
  {
    value: 'MARKETING',
    label: onboardingStrings.companyIndustryOptionMarketing,
  },
  {
    value: 'EDUCATION',
    label: onboardingStrings.companyIndustryOptionEducation,
  },
  { value: 'LEGAL', label: onboardingStrings.companyIndustryOptionLegal },
  {
    value: 'ARCHITECTURE_CONSTRUCTION',
    label: onboardingStrings.companyIndustryOptionArchitecture,
  },
  { value: 'RETAIL', label: onboardingStrings.companyIndustryOptionRetail },
  {
    value: 'INDUSTRIAL',
    label: onboardingStrings.companyIndustryOptionIndustrial,
  },
  {
    value: 'HOSPITALITY',
    label: onboardingStrings.companyIndustryOptionHospitality,
  },
  {
    value: 'MEDIA_ENTERTAINMENT',
    label: onboardingStrings.companyIndustryOptionMedia,
  },
  {
    value: 'TRANSPORTATION',
    label: onboardingStrings.companyIndustryOptionTransportation,
  },
  {
    value: 'PUBLIC_GOVERNMENT',
    label: onboardingStrings.companyIndustryOptionPublic,
  },
  { value: 'OTHER', label: onboardingStrings.companyIndustryOptionOther },
]);

const isFormValid = computed(() => {
  return (
    onboardingData.value.accountTypeInfo.accountType !== undefined &&
    onboardingData.value.accountTypeInfo.accountType in AccountType
  );
});

onMounted(() => {
  sendAnalytics({ eventName: 'onboarding_use_case_view' });
  emit('enableToContinue', isFormValid.value);
});

watch(isFormValid, (val) => {
  emit('enableToContinue', val);
});
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.whoAreCreatingSigForFormHeader }}
  </div>
  <radio-button
    v-model="onboardingData.accountTypeInfo.accountType"
    :options="accountTypeOptions"
    option-width="243px"
    aid="ONBOARDING_ACCOUNT_TYPE"
  ></radio-button>
  <div
    v-if="
      onboardingData.accountTypeInfo.accountType !== undefined &&
      onboardingData.accountTypeInfo.accountType in AccountType
    "
  >
    <div class="text-3xl font-semibold form-header">
      {{ onboardingStrings.whoAreCreatingSigForFormIndustryHeader }}
    </div>
    <radio-button
      v-model="onboardingData.accountTypeInfo.companyIndustry"
      :options="companyIndustryOptions"
      class="industry-options"
      aid="ONBOARDING_INDUSTRY"
    ></radio-button>
  </div>
</template>
<style lang="css" scoped>
.form-header {
  margin-top: 60px;
  margin-bottom: 24px;
}

@media screen and (min-width: 1347px) {
  .industry-options {
    width: 720px;
  }
}
</style>
